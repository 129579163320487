import "./index.less";
import "../../../../../static/css/form.less";

import Vue from "vue";
import Component from "vue-class-component";
import {
  zsChildGuarderInfoService,
  ChildGuarderInfo,
} from "@/app/apiServices/zsChildGuarderInfo/public-api";
import {
  dictionaryService,
  IDictionary,
} from "@/app/apiServices/dictionary/public-api";
import { IdCardValid } from "@/core/utils/IdCard/idCardValid";
import { mobilePhoneVaild } from "@/core/utils/mobilePhoneVaild";
import { thisCity_stepsList } from "@/core/utils/enum/thisCity";
import { getStepsIcon } from "@/core/utils";
import { filter } from "@/core/utils";
import { hasRegister } from "@/config/message";
import storage from "@/core/utils/storage";

@Component
export default class ActionFamilyMembersInformation extends Vue {
  formModel = new ChildGuarderInfo();
  formRule = {
    relationshipCode: [
      { required: true, message: "请选择与幼儿关系", trigger: "blur" },
    ],
    name: [{ required: true, message: "请输入名字", trigger: "blur" }],
    cardTypeCode: [
      { required: true, message: "请选择证件类型", trigger: "blur" },
    ],
    cardNO: [
      { required: true, message: "请输入证件号码", trigger: "blur" },
      { required: true, validator: this.validateSFZ, trigger: "blur" },
    ],
    telephone: [
      { required: true, message: "请输入电话", trigger: "blur" },
      {
        type: "string",
        min: 11,
        max: 11,
        message: "请输入11位电话！",
        trigger: "blur",
      },
      {
        validator: (rule: any, value: string, callback: any) => {
          const telephoneValid = mobilePhoneVaild(value);
          if (telephoneValid === true) {
            callback();
          } else {
            callback(new Error(`${telephoneValid}`));
          }
        },
        trigger: "blur",
      },
    ],
  };

  //与幼儿关系
  relationListDic: IDictionary[] = [];
  //证件类型
  zjlxListDic: IDictionary[] = [];
  isAdd = true;
  stepsList = thisCity_stepsList;
  activeSteps = 1;
  guarderList = 0;

  getStepsIcon(index: number) {
    return getStepsIcon(index, this.activeSteps);
  }

  get showDefaultGuarder() {
    if (!this.isAdd) {
      return false;
    }
    return this.guarderList > 0;
  }

  //验证身份证(防止证件号被动使用默认值)
  validateSFZ(rule: any, value: string, callback: any) {
    if (this.formModel.cardTypeCode === "ZJLX_sfz") {
      if (value.length == 18) {
        const gbSFZValidator = IdCardValid(value);
        if (gbSFZValidator === true) {
          callback();
        } else {
          callback(new Error(`${gbSFZValidator}`));
        }
      } else {
        callback(new Error("请输入18位证件号码！"));
      }
    } else {
      callback();
    }
  }

  formOnClick() {
    const { viewDataForm }: any = this.$refs;
    viewDataForm.validate((valid: boolean) => {
      if (valid) {
        const { gradeCode, childId }: any = storage.GET_RegistrationInfo();
        this.formModel.gradeCode = gradeCode;
        this.formModel.childId = childId;

        //根据code获取名称
        this.formModelCodeToName();

        zsChildGuarderInfoService
          .saveGuarderInfoPage(this.formModel)
          .then((res) => {
            if (res.verifyResult.success) {
              this.$toast({
                message: `${this.isAdd === true ? "添加" : "修改"}成功`,
                position: "top",
                onClose: () => {
                  this.$router.push(`/thisCity/familyMemberInformation`);
                },
              });
            }
            else {
              if (res.verifyResult.exceptionCode == "GOHOME") {
                this.$dialog.alert({
                  message: hasRegister,
                }).then(() => {
                  this.$router.push("/record");
                });
              }
              else {
                this.$toast({
                  message: res.verifyResult.message,
                  position: 'top'
                });
              }
            }
          });
      }
    });
  }
  async viewLoad() {
    const { id }: any = storage.GET_FamilyMember();
    //修改
    if (id !== 0) {
      this.formModel = await zsChildGuarderInfoService.getGuarder(id);
      this.isAdd = false;
    }
    //新增
    else {
      // this.formModel.defaultGuarder = storage.GET_GuarderList().length === 0;
      this.guarderList = storage.GET_GuarderList().length;

      //初始化表单数据
      this.formModel.relationshipCode = "GuarderRelation_51";
    }
  }
  // 根据code 获得 name
  formModelCodeToName() {
    //称谓（与幼儿关系）
    this.formModel.relationship = filter(this.relationListDic, this.formModel.relationshipCode);
    //证件类型
    this.formModel.cardType = filter(this.zjlxListDic, this.formModel.cardTypeCode);
  }

  back() {
    this.$router.push(`/thisCity/familyMemberInformation`);
  }

  async mounted() {
    this.zjlxListDic = await dictionaryService.getZjlxList(2);
    this.relationListDic = await dictionaryService.getRelationList();
    this.viewLoad();
  }
}